import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/common/Layout"
import Gokart from '../components/tracks/Gokart'
import { MetaData } from '../components/common/meta'
import kartingImage from '../images/go-karting-driver.jpg'

const Track = ({ track }) => (
  <li>
    <a href={`/go-karting/${track.Url ? track.Url : `${track.Name.toLowerCase().replace(/[^a-zA-Z0-9-_ ]/g, '').replace(/ /g, '-')}`}`}>{track.Name}</a>
  </li>
)

const GoKarting = ({ location, data }) => (
  <>
    <MetaData
                title="Go Karting Near Me - Outdoor and Indoor Tracks"
                description="Show go karting tracks near me. List of popular outdoor and indoor gokart tracks."
                location={location}
                image={kartingImage}
            />
  <Layout showLocation>
    <Gokart hideTracks/>
    <h1>Outdoor Go Karting</h1>
    <ul className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {data.allAirtable.nodes.map(track => (
        <Track track={track.data} key={track.id} />
      ))}
    </ul>
  </Layout>
  </>
)

export default GoKarting

export const query = graphql`
  {
    allAirtable {
      nodes {
        id
        data {
          country:Country
          Name
          Url
        }
      }
    }
  }
`
